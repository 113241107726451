import MessageMap from '../i18n';

const es419: MessageMap = {
  TEST: {
    TEST_TRANSLATE: 'Translate {value}',
  },
  FORMAT: {
    CURRENCY: 'USD',
  },
  SIDEBAR: {
    COMPANY_PROFILE_SETUP: 'Configuración del perfil de la empresa',
    COMPANIES: 'Perfiles de la compañía',
    STORES: 'Tiendas',
    VENDORS_GROUP: 'Sellers',
  },
  TOGGLE: {
    ACTIVE_TEXT: 'Activo',
    INACTIVE_TEXT: 'Inactivo',
  },
  COMPONENTS: {
    NOT_FOUND_HEADER_TEXT: 'Mmm… Parece que esta página se ha ido',
    NOT_FOUND_SUBHEADER_TEXT: 'Parece que esta página no se encontró o no existe.',
    NOT_FOUND_HOME_BTN: 'Volver',
    ERROR_GENERIC: 'Algo salió mal',
    TOGGLE_ACTIVE_STATUS: 'Activo',
    TOGGLE_INACTIVE_STATUS: 'Desactivado',
    STEPPER_BACK_BUTTON: 'Volver',
    STEPPER_NEXT_BUTTON: 'Siguiente',
    OPTION_YES: 'Sí',
    OPTION_NO: 'No',
  },
  HOME: {
    TITLE: 'Configuración del perfil de la empresa',
    WELCOME: 'Bienvenido a la Configuración del perfil de la empresa del BEES',
    WELCOME_DESCRIPTION:
      'Descubre, accede y administra todos los perfiles de tiendas y empresas. Verifica todos los tipos de proveedores, socios y modelos de servicio.',
    GET_TOOLS: 'Conoce las herramientas',
    GET_TOOLS_DESCRIPTION:
      'Acceso rápido a todas las herramientas disponibles para gestionar socios de todos los modelos de negocios.',
    APP_STORES_TITLE: 'Tiendas',
    APP_STORES_DESCRIPTION: 'Acceda y gestione tiendas de cada zona y sus empresas.',
    APP_COMPANIES_TITLE: 'Perfiles de la compañía',
    APP_COMPANIES_DESCRIPTION: 'Accede y administra los perfiles registrados en la aplicación.',
  },
  COMPANY_LIST: {
    TITLE: 'Perfiles de la compañía',
    TABLE_LEGAL_NAME: 'Razón social',
    TABLE_DISPLAY_NAME: 'Nombre comercial',
    TABLE_GOVERNMENT_ID: 'ID gubernamental',
    TABLE_LEGAL_NAME_AND_GOVERNMENT_ID: 'Razón social y ID gubernamental',
    TABLE_VENDOR_ID: 'ID del proveedor',
    TABLE_SERVICE_MODEL: 'Modelo de servicio',
    SEARCH: 'Búsqueda por',
    SEARCH_PLACEHOLDER: 'Búsqueda por razón social, nombre comercial o ID gubernamental',
    VENDOR_ID_TOOLTIP: 'Número generado automáticamente por nuestra base de datos',
    ADD_COMPANY: 'Agregar nueva compañía',
    VENDOR_ID_COPIED: 'Se copió el ID del proveedor en el portapapeles',
    FILTER_BY_COUNTRY: 'Filtrar por pais',
    CANNOT_LOAD_SUPPORTED_COUNTRIES:
      'Su cuenta no tiene un país admitido relacionado. Habla con nuestro soporte',
    COMPANY_CREATION_COUNTRY_NOT_SUPPORTED:
      'Por el momento, no se admite la creación de una empresa para {countryName}',
    EMPTY_MESSAGE: 'No se encontraron compañías.',
    LOADING_MESSAGE: 'Cargando compañías...',
    LINES_PER_PAGE: 'Lineas por pagina: {pageSize}',
  },
  ADD_COMPANY: {
    TITLE: 'Añadir empresa',
    STEP1_LABEL: 'Información de la compañía',
    STEP1_DETAIL: 'Ingrese los detalles de registro.',
    STEP1_CONTENT_TITLE: 'Ingrese la información de la compañía',
    STEP1_CONTENT_SUBTITLE: 'Puede editar esta información más tarde.',
    STEP2_LABEL: 'Modelo de servicio',
    STEP2_DETAIL: 'Selecciona el modelo de servicio.',
    STEP2_CONTENT_TITLE: 'Agregar compañía',
    STEP2_CONTENT_SUBTITLE: 'Esto definirá cómo operará la compañía.',
    STEP2_CONTENT_TOOLTIP: 'P se refiere al modelo de socio. L se refiere al modelo logístico.',
    CANCEL_BUTTON: 'Cancelar',
    ADD_BUTTON: 'Agregar',
    TITLE_1P_1L_SINGLE_PROVIDER: '1P (1L) - Proveedor único',
    DESCRIPTION_1P_1L_SINGLE_PROVIDER: 'Los productos son vendidos y entregados por BEES.',
    TITLE_3P_3L_SAME_STORE: '3P (3L) - Mismo negocio',
    DESCRIPTION_3P_3L_SAME_STORE: 'Los Productos son vendidos y entregados por el fornecedor.',
    TITLE_3P_DISTRIBUTOR: '3P - Distributor',
    DESCRIPTION_3P_DISTRIBUTOR: 'Los Productos son vendidos y entregados por el distribuidor.',
    TITLE_3P_3L_WHOLESALER: '3P - Mayorista',
    DESCRIPTION_3P_3L_WHOLESALER: 'Los products son vendidos y entregados por el mayorista. ',
    TITLE_3P_3L_STOREFRONT: '3P (3L) -  Tienda de la empresa asociada',
    DESCRIPTION_3P_3L_STOREFRONT:
      'Los productos son vendidos por el proveedor en su tienda y entregados por ellos.',
    LEGAL_NAME_LABEL: 'Razón social',
    DISPLAY_NAME_LABEL: 'Nombre comercial',
    GOVERNMENT_ID_LABEL: 'ID gubernamental',
    GOVERNMENT_ID_TOOLTIP:
      'El número de caractéres puede variar, dependiendo el país de origen, no puede contener caracteres especiales.',
    TAX_ID_LABEL: 'ID gubernamental',
    TAX_ID_ERROR_LABEL: 'Ingrese una ID gubernamental válida. Este campo es obligatorio.',
    ADDRESS_LABEL: 'Address',
    CITY_LABEL: 'Ciudad',
    STATE_LABEL: 'Estado',
    ZIP_CODE_LABEL: 'Código postal',
    COUNTRY_LABEL: 'País',
    COMPANY_TIER_TITLE: 'Nivel de la empresa',
    COMPANY_TIER_DESCRIPTION: 'Seleccione el tipo de cuenta.',
    COMPANY_TIER_DEFAULT_LABEL: 'Plata',
    COMPANY_TIER_DEFAULT_DESCRIPTION: 'Acceda sólo a la información habitual.',
    COMPANY_TIER_SILVER_LABEL: 'Plata',
    COMPANY_TIER_SILVER_DESCRIPTION: 'Acceda sólo a la información habitual.',
    COMPANY_TIER_GOLD_LABEL: 'Oro',
    COMPANY_TIER_GOLD_DESCRIPTION: 'Accede a funciones de pago.',
    IS_MANUFACTURER_LABEL: '¿Es la empresa un fabricante?',
    IS_MANUFACTURER_DESCRIPTION:
      'Para proporcionarle los mejores servicios, necesitamos saber si la empresa produce bienes a partir de materias primas.',
    IS_MANUFACTURER_OPTION_YES: 'Sí, es un fabricante',
    IS_MANUFACTURER_OPTION_NO: 'No, no es un fabricante',
  },
  VENDOR_FORM: {
    TITLE: 'Acerca de tu negocio',
    DESCRIPTION: 'Start registering a new company by adding all the required information',
    LABEL_LEGAL_NAME: 'Razón social',
    LABEL_DISPLAY_NAME: 'Nombre comercial',
    LABEL_GOVERNMENT_ID: 'ID gubernamental',
    TOOLTIP_GOVERNMENT_ID:
      'El número de caractéres puede variar, dependiendo el país de origen, no puede contener caracteres especiales.',
    TOOLTIP_3P3L_ID:
      'Proveedores 3P (3L) que trabajan como operación logística subcontratada para productos BEES',
    LABEL_LEGAL_ADDRESS: 'Dirección comercial',
    LABEL_CITY: 'Ciudad',
    LABEL_STATE: 'Estado',
    LABEL_ZIP_CODE: 'Código postal',
    LABEL_COUNTRY: 'País',
    LABEL_SERVICE_MODEL: 'Modelo de servicio',
    REQUIRED: 'Este campo es obligatorio.',
    GOVERNMENT_ID_DUPLICATED:
      'Este ID gubernamental ya está registrado. Ingrese un nuevo ID gubernamental y vuelva a intentarlo.',
  },
  SERVICE_MODEL: {
    SELECT_SERVICE_MODEL_TEXT: 'Selecciona el tipo de modelo de servicio que quieres crear.',
    TITLE_1P_1L: '1P (1L)',
    DESCRIPTION_1P_1L: 'Vendido y enviado por BEES.',
    TITLE_1P_3L_REWARDS: '1P (3L) Recompensas',
    DESCRIPTION_1P_3L_REWARDS:
      'Vendido por BEES con puntos de recompensa y enviado por un proveedor.',
    TITLE_3P_3L_SAME_STORE: 'Empresa Core/PRO - 3P (3L) Tienda Bees',
    DESCRIPTION_3P_3L_SAME_STORE: 'Vendido en la app de BEES y enviado por un proveedor.',
    TITLE_3P_3L_STOREFRONT: 'Empresa DTaaS - 3P (3L) Tienda Exclusiva',
    DESCRIPTION_3P_3L_STOREFRONT: 'Vendido por un proveedor en el local y enviado por ellos.',
    TITLE_3P_1L: '3P (1L)',
    DESCRIPTION_3P_1L: 'Comprado por un proveedor y enviado por BEES.',
  },
  ACCESSES: {
    EDITOR: 'Editor',
    ADMIN: '{admin}',
    ORDER_MANAGER: 'Order Manager',
    PRODUCT_EDITOR: 'Product Editor',
    VIEWER: 'Espectador',
  },
  VENDOR: {
    VENDOR_ID:
      'La indentificación del proveedor es un número generado automáticamente en nuestra base de datos.',
  },
  PAGINATE: {
    ROWS_PER_PAGE: 'Filas por página',
  },
  VENDOR_SERVICE: {
    ERROR_LOAD_ALL: 'No es posible cargar todas las empresas.',
    CREATE_VENDOR_SUCCESS: 'Una nueva empresa ha sido agregada',
    CREATE_VENDOR_ERROR_TITLE: '¡Algo falló!',
    CREATE_VENDOR_ERROR_DESCRIPTION: 'Por favor, intente nuevamente.',
  },
  USER_SERVICE: {
    ERROR_LOAD_ALL: 'No es posible cargar todos los usuarios.',
    CREATE_USER_SUCCESS: 'Se ha agregado un nuevo usuario',
    CREATE_VENDOR_ERROR: '¡Algo falló!',
    CREATE_USER_FAIL: 'Incapaz de agregar un nuevo usuario',
    CREATE_USER_ERROR_TITLE: '¡Algo falló!',
    CREATE_USER_ERROR_DESCRIPTION: 'Por favor, intente nuevamente.',
    RESEND_INVITE_SUCCESS: 'Invitación enviada con éxito',
    RESEND_INVITE_FAIL: 'Hubo un problema al enviar la invitación, intente nuevamente',
    BLOCK_USER_SUCCESS: 'Usuario desactivado correctamente',
    BLOCK_USER_FAIL: 'Algo salió mal al inactivar al usuario',
    UNBLOCK_USER_SUCCESS: 'Usuario activado con éxito',
    UNBLOCK_USER_FAIL: 'Algo salió mal al activar al usuario',
  },
  MODAL: {
    BACK: 'Volver',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
    CLOSE: 'Cerrar',
    FINISH: 'Finalizar',
    GO_BACK: 'Volver atrás',
    NEXT: 'Siguiente',
    CONFIRM: 'Confirmar',
    TRY_AGAIN_FAILED: 'Vuelve a intentar',
    CONFIRMATION_MODAL_TITLE: '¿Confirmar Guardado?',
    CONFIRMATION_MODAL_DESCRIPTION:
      'Tenga en cuenta que cualquier cambio realizado podría impactar sus productos y configuraciones.',
  },
  COMPANY_DETAILS: {
    TITLE: 'Información general',
    IMAGE_TITLE: 'Logo de la empresa',
    IMAGE_UNAUTHORIZED_LABEL: 'No tiene permiso para cargar un logo de la empresa.',
    FIELD_REQUIRED: 'Campo requerido',
    LABEL_VENDOR_ID: 'ID del proveedor',
    LABEL_VENDOR_ID_TOOLTIP:
      'La indentificación del proveedor es un número generado automáticamente en nuestra base de datos.',
    LABEL_COUNTRY: 'País',
    LABEL_LEGAL_NAME: 'Razón social',
    LABEL_DISPLAY_NAME: 'Nombre comercial',
    LABEL_GOV_ID: 'ID gubernamental',
    LABEL_TAX_ID: 'ID gubernamental',
    LABEL_ADDRESS: 'Dirección',
    LABEL_TIER: 'Nivel de la empresa',
    LABEL_IS_MANUFACTURER: '¿Es un fabricante?',
    LABEL_CITY: 'Ciudad',
    LABEL_STATE: 'Estado',
    LABEL_ZIP: 'Código postal',
    LABEL_SRV_MODEL: 'Modelo de servicio',
    LABEL_SINCE: 'Proveedor desde',
    LABEL_DETAILS_TAB: 'Detalles',
    EDIT_BUTTON: 'Editar',
    DONE_EDITING_BUTTON: 'Finalizar edición',
    CANCEL_BUTTON: 'Cancelar',
    SAVE_CHANGES_BUTTON: 'Guardar cambios',
    LAST_UPDATE: 'Última actualización:',
    LOAD_VENDOR_ERROR_DEFAULT: '¡Algo falló!',
    UPDATE_VENDOR_SUCCESS_DEFAULT: 'Empresa editada con éxito',
    UPDATE_VENDOR_ERROR_DEFAULT: 'No se pudo actualizar la información de la empresa',
    CANCELLATION_MODAL_TITLE: '¿Estás seguro/a de que quieres continuar?',
    CANCELLATION_MODAL_CONTENT:
      'Hay cambios sin guardar que se perderán. Si continuas, no podrás deshacer esta acción.',
    CANCELLATION_MODAL_KEEP_EDITING_BTN: 'No, seguir editando',
    CANCELLATION_MODAL_PROCEED_BUTTON: 'Sí, continuar',
    BTN_SAVE_CHANGES: 'Guardar cambios',
    BTN_CANCEL: 'Cancelar',
    EDIT_STORES: 'Editar tiendas',
    NO_ASSOCIATED_STORE: 'No hay tiendas asociadas',
    ASSOCIATED_STORE_LABEL: 'Tienda vinculada',
    ASSOCIATE_MODAL_TITLE: 'Asocia la compañía con diferentes tiendas',
    ASSOCIATE_MODAL_SUBTITLE:
      'Asocia la compañía {vendorName} con diferentes tiendas seleccionándolas.',
    CONFIRM_MODAL_TITLE: '¿Confirmar vinculación?',
    CONFIRM_MODAL_PROCESSING_TITLE: 'Asociación en progreso...',
    CONFIRM_MODAL_PROCESSED_TITLE: 'Procesamiento finalizado',
    CONFIRM_MODAL_DESCRIPTION:
      'Estás a punto de vincular la compañía {vendorName} con las tiendas seleccionadas.',
    CONFIRM_MODAL_PROCESSING_DESCRIPTION:
      'Una vez que el proceso haya terminado, puedes cerrar este modal o intentarlo de nuevo.',
    CONFIRM_MODAL_ALL_FAILED_DESCRIPTION:
      'La asociación ha terminado. Ahora puedes cerrar esta ventana o repetir el proceso.',
    CONFIRM_MODAL_PROCESSED_DESCRIPTION:
      'El proceso de asociación ha finalizado. Ahora puedes cerrar esta ventana o intentarlo nuevamente.',
    NO_STORES_SELECTED: 'No se han seleccionado tiendas.',
    STORES_TO_ASSOCIATE: 'Tiendas para asociar',
    STORES_TO_DISSOCIATE: 'Tiendas para disociar',
    STORE_SUCCESS_STATUS: 'Éxito',
    STORE_FAILED_STATUS: 'Fallido',
    SUCCESS_NOTIFY_MESSAGE: 'La empresa {company} se actualizó correctamente.',
    SUCCESS_NOTIFY_BUTTON: 'Ir a la lista de tiendas',
    NO_STORES_AVAILABLE_PLACEHOLDER: 'No hay tienda disponible para asociar',
  },
  DISTRIBUTION_CENTER_TAB: {
    TITLE: 'Centros de distribución',
    LABEL_DISTRIBUTION_CENTER_TAB: 'Centros de distribución',
    TABLE_DISTRIBUTION_CENTER: 'Centro de distribución',
    TABLE_COVERAGE: 'Cobertura',
    TABLE_DELIVERY_WINDOW: 'Ventana de Entrega',
    EMPTY_EMAIL_LIST: 'No hay centros de distribución en este momento.',
    ADD_DDC_BUTTON_LABEL: 'REGISTRAR CDD',
    ERROR_TITLE: '¡Algo falló!',
    ERROR_CONTENT:
      'Lo lamentamos, pero estamos con dificultades técnicas. Intenta de nuevo más tarde.',
    RANGES: 'rangos',
    BUSINESS_DAYS: 'días hábiles',
    TO: 'para ',
    AREAS: 'áreas',
    TABLE_CONTEXT_MENU_EDIT_BTN: 'Editar',
    TABLE_CONTEXT_MENU_DELETE_BTN: 'Eliminar',
    CUSTOM_AREA: 'custom area',
    CUSTOM_AREAS: 'custom areas',
    DELETE_MODAL_TITLE: 'Delete distribution center',
    DELETE_MODAL_CANCEL: 'Cancel',
    DELETE_MODAL_DESCRIPTION: "Are you sure you want to delete ''{DCName}'' distribution center?",
    DELETE_MODAL_SUCCESS: 'Distribution center successfully deleted.',
    DELETE_MODAL_ERROR:
      "Distribution center couldn't be deleted due to a system error. Try again later.",
  },
  COMPANY_DETAILS_MODAL_FORM: {
    ADD_COMPANY_DETAILS_MODAL_TITLE: 'Adicionar compañía',
    COMPANY_DETAILS_MODAL_TITLE: 'Editar la información del proveedor',
    COMPANY_DETAILS_MODAL_SUB_TITLE:
      'Completa el formulario para registrar una empresa. Luego, podrás agregar miembros.',
    LABEL_COUNTRY: 'País',
    LABEL_LEGAL_NAME: 'Razón social',
    LABEL_DISPLAY_NAME: 'Nombre comercial',
    LABEL_GOV_ID: 'ID gubernamental',
    GOV_ID_TOOLTIP:
      'El número de caractéres puede variar, dependiendo el país de origen, no puede contener caracteres especiales.',
    LABEL_LEGAL_ADDRESS: 'Dirección comercial',
    LABEL_CITY: 'Ciudad',
    LABEL_STATE: 'Estado',
    LABEL_ZIP: 'Código postal',
    LABEL_SRV_MODEL: 'Modelo de servicio',
    REQUIRED_ERROR_MESSAGE: 'Este campo es obligatorio',
    SUBMIT_BUTTON: 'Guardar',
    ADD_COMPANY_BUTTON: 'Adicionar compañía',
    ADD_COMPANY_CANCEL_BUTTON: 'Cancelar',
    ERROR_MODAL_TITLE: '¡Algo falló!',
    ERROR_MODAL_SUB_TITLE:
      'Lo lamentamos, pero estamos con dificultades técnicas. Intenta de nuevo más tarde',
    GO_BACK_BUTTON_LABEL: 'Volver',
    CLOSE_BUTTON_LABEL: 'Cerrar',
  },
  GOV_ID_COMPONENT: {
    LABEL: 'ID gubernamental',
    INVALID: 'Identificación gubernamental inválida',
  },
  ADD_USER_MODAL: {
    SUBMIT_BUTTON_TITLE: 'Agregar',
    CANCEL_BUTTON_TITLE: 'Cancelar',
    CLOSE_BUTTON_TITLE: 'Cerrar',
    MODAL_SUB_TITLE: 'E-mails agregados aquí recibirán un link para acceder al Partner Portal',
    MODAL_TITLE: 'Agregar usuarios',
    ERROR_MODAL_TITLE: '¡Algo falló!',
    ERROR_MODAL_SUB_TITLE:
      'Lo lamentamos, pero estamos con dificultades técnicas. Intenta de nuevo más tarde',
    GO_BACK_BUTTON_LABEL: 'Volver',
    CLOSE_BUTTON_LABEL: 'Cerrar',
    REQUIRED_ERROR_MESSAGE: 'Este campo es obligatorio',
    INVALID_EMAIL_ERROR_MESSAGE: 'E-mail inválido o incorrecto',
    DUPLICATED_EMAIL_ERROR_MESSAGE: 'El Email ya existe',
    DUPLICATED_EMAIL_INPUT_ERROR_MESSAGE: 'Esta información ya está siendo utilizada en otro campo',
    ADD_USER_ERROR_DEFAULT: '¡Algo falló!',
  },
  FORMIK_USER_LIST: {
    EMAIL: 'E-mail',
    ACCESS: 'Acceder como',
  },
  COMPANY_USERS: {
    TITLE: 'Administración de usuarios',
    LABEL_USERS_TAB: 'Usuarios',
    TABLE_NAME: 'Nombre',
    TABLE_EMAIL: 'E-mail',
    TABLE_ACCESS: 'Acceder como',
    TABLE_STATUS: 'Status',
    EMPTY_EMAIL_LIST: 'No existe un usuario registrado en este momento.',
    BUTTON_LABEL: 'Agregar usuarios',
    RESEND_INVITE_BUTTON: 'Reenviar invitacíon',
    BLOCK_USER_BUTTON: 'Desactivar usuario',
    UNBLOCK_USER_BUTTON: 'Activar usuario',
  },
  BLOCK_UNBLOCK_USER_MODAL: {
    BLOCK_BUTTON: 'DESACTIVAR',
    UNBLOCK_BUTTON: 'ACTIVAR',
    BLOCK_TITLE: 'Desactivar usuario',
    UNBLOCK_TITLE: 'Activar usuario',
    CANCEL: 'CANCELAR',
    BLOCK_TEXT:
      'Está a punto de desactivar esta cuenta. El usuario no podrá acceder a la plataforma después de eso. Puedes activar la cuenta cuando quieras.',
    UNBLOCK_TEXT: 'Está a punto de activar este usuario. Estás seguro de que quieres continuar?',
  },
  USER_STATUS: {
    PENDING: 'Pendiente',
    BLOCKED: 'Inactivo',
    ACTIVE: 'Activo',
  },
  ACCOUNT_TIER: {
    LABEL_ACCOUNT_TIER: 'Nivel de cuenta',
    DESCRIPTION_ACCOUNT_TIER_CREATE: 'Seleccione el tipo de cuenta que está creando.',
    DESCRIPTION_ACCOUNT_TIER_EDIT: 'Seleccione el tipo de cuenta.',
    LABEL_TIER_SILVER: 'Plata',
    DESCRIPTION_TIER_SILVER: 'Acceda sólo a la información habitual.',
    LABEL_TIER_DEFAULT: 'Plata',
    DESCRIPTION_TIER_DEFAULT: 'Acceda sólo a la información habitual.',
    LABEL_TIER_GOLD: 'Oro',
    DESCRIPTION_TIER_GOLD: 'Accede a funciones de pago.',
    LABEL_CHANGE_TIER: 'Cambiar nivel',
    LABEL_EDIT_TIER: 'Editar nivel',
    FETCH_AVAILABLE_TIERS_ERROR:
      'No se puede cargar la información del país. Por favor, selecciónelo de nuevo.',
    FETCH_AVAILABLE_TIERS_SOMETHING_WENT_WRONG:
      'Algo salió mal al intentar cargar la información del país.',
    CHANGE_TIER_SUCCESS_MESSAGE: 'Nivel de la cuenta exitosamente actualizado.',
    CHANGE_TIER_ERROR_MESSAGE:
      'No se puede actualizar el nivel de la cuenta. Vuelva a intentarlo más tarde.',
  },
  PAYMENT_AND_ORDERS: {
    TAB_TITLE: `{country, select,
    US {Pedidos}
    other {Pago y pedidos}
    }`,
    PAYMENT_METHODS_TITLE: 'Formas de pago',
    PAYMENT_METHODS_SUBTITLE: 'Selecciona el método de pago del socio.',
    BANK_SLIP_PAYMENT_METHOD_INPUT_LABEL: 'Días hasta el vencimiento',
    PAYMENT_MANAGER_BTN: 'Administrar formas de pago',
    PAYMENT_MANAGER_MODAL_TITLE: 'Administrar formas de pago',
    PAYMENT_MANAGER_MODAL_DESCRIPTION: 'Seleccione un método de pago existente o añada uno nuevo',
    PAYMENT_MANAGER_MODAL_EXISTING_LABEL: 'Seleccione un método de pago existente',
    PAYMENT_MANAGER_MODAL_SELECT_PLACEHOLDER: 'Seleccionar',
    PAYMENT_MANAGER_MODAL_NONE_AVAILABLE_METHODS: 'No hay método de pago disponible',
    PAYMENT_MANAGER_MODAL_CREATE_CUSTOM_LABEL: 'Crear método de pago personalizado',
    PAYMENT_MANAGER_MODAL_CREATE_CUSTOM_BTN: 'Agregar',
    PAYMENT_MANAGER_MODAL_CONFIRM: 'Confirmar',
    PAYMENT_MANAGER_MODAL_CANCEL: 'Cancelar',
    CUSTOM_PAYMENT_MODAL_TITLE: 'Agregar nuevo método de pago',
    CUSTOM_PAYMENT_MODAL_DESCRIPTION: 'Defina los detalles para el nuevo método de pago.',
    CUSTOM_PAYMENT_MODAL_TOOLTIP: 'Este idioma se ajusta automáticamente según la zona del socio',
    CUSTOM_PAYMENT_MODAL_LABEL: 'Nombre del método de pago en {language}',
    CUSTOM_PAYMENT_MODAL_PLACEHOLDER: 'Ejemplo: {example}',
    CUSTOM_PAYMENT_MODAL_CONFIRM: 'Confirmar',
    CUSTOM_PAYMENT_MODAL_BACK: 'Volver',
    INPUT_ENGLISH_LABEL: 'Inglés',
    INPUT_ENGLISH_PLACEHOLDER: 'Cash',
    INPUT_PORTUGUESE_LABEL: 'Portugués',
    INPUT_PORTUGUESE_PLACEHOLDER: 'Dinheiro',
    INPUT_SPANISH_LABEL: 'Español',
    INPUT_SPANISH_PLACEHOLDER: 'Efectivo',
    INPUT_CHINESE_LABEL: 'Chino',
    INPUT_CHINESE_PLACEHOLDER: '现金',
    INPUT_FRENCH_LABEL: 'Francés',
    INPUT_FRENCH_PLACEHOLDER: 'Espèces',
    INPUT_KOREAN_LABEL: 'Coreano',
    INPUT_KOREAN_PLACEHOLDER: '현금',
    INPUT_FILIPINO_LABEL: 'Filipino',
    INPUT_FILIPINO_PLACEHOLDER: 'Pera',
    INPUT_INDONESIAN_LABEL: 'Indonesio',
    INPUT_INDONESIAN_PLACEHOLDER: 'Tunai',
    INPUT_DUTCH_LABEL: 'Holandés',
    INPUT_DUTCH_PLACEHOLDER: 'Contant',
    INPUT_GERMAN_LABEL: 'Alemán',
    INPUT_GERMAN_PLACEHOLDER: 'Bargeld',
    ORDER_CONFIGURATIONS_TITLE: 'Configuraciones de pedidos',
    MINIMUM_ORDER_LABEL: 'Mínimo por pedido',
    MINIMUM_ORDER_SUBTITLE: 'Establece un mínimo para la cantidad del pedido o el monto total.',
    MINIMUM_ORDER_INPUT_LABEL: 'Total de pedido mínimo',
    MAXIMUM_ORDER_LABEL: 'Máximo por pedido',
    MAXIMUM_ORDER_SUBTITLE: 'Establece un máximo para la cantidad del pedido o el monto total.',
    MAXIMUM_ORDER_INPUT_LABEL: 'Total de pedido máximo',
    MINIMUM_MAXIMUM_OPTIONAL: '(opcional)',
    MINIMUM_MAXIMUM_PRODUCT_QUANTITY: 'Cantidad del producto',
    MINIMUM_MAXIMUM_ORDER_TOTAL: 'Total del pedido',
    ORDER_NOTIFICATIONS_TITLE: 'Notificaciones de pedido',
    ORDER_NOTIFICATIONS_SUBTITLE: 'Seleccione los destinatarios',
    ORDER_NOTIFICATIONS_NO_SALES_REP: 'Sin representantes de ventas',
    ORDER_NOTIFICATIONS_PRIMARY_SALES_REP: 'Representantes de ventas principales',
    ORDER_NOTIFICATIONS_ALL_SALES_REPS: 'Todos los representantes de ventas',
    ORDER_NOTIFICATIONS_ALL_SALES_REPS_SUPERVISOR:
      'Todos los representantes de ventas + líder/gerente del equipo',
    ORDER_NOTIFICATIONS_ALERT_WARNING:
      'Los representantes de ventas solo recibirán notificaciones de pedidos si sus correos electrónicos están previamente registrados en BEES. Comunícate con el soporte de BEES si tienes alguna pregunta.',
    ORDER_NOTIFICATIONS_EMAIL_LIST: 'Lista de correos electrónicos',
    ORDER_NOTIFICATIONS_EMAIL_LIST_SUBTITLE: 'Usa comas para separar los correos electrónicos',
    REDEEM_TITLE: 'Participación en el Club B',
    REDEEM_DESCRIPTION:
      '¿Participarán en Club B y permitirán que los pedidos se canjeen por completo con puntos?',
    REDEEM_DESCRIPTION_TOOLTIP:
      'Al permitir que los clientes canjeen productos pagando por completo con puntos, no se necesita un monto mínimo de pago.',
    EMPTIES_CONFIGURATION_TITLE: 'Configuración de envases vacíos',
    EMPTIES_DESCRIPTION: '¿Habilitar carrito solo con envases vacíos?',
    EMPTIES_DESCRIPTION_TOOLTIP:
      'Esto permite que los PDV devuelvan los envases vacíos sin comprar nada.',
  },
  RECOMMENDATION: {
    TAB_LABEL: `Recomendaciónes{country, select,
      BR { y clientes}
      other {}
    }`,
    PRODUCT_RECOMMENDATIONS: 'Recomendaciones de productos',
    SUGGESTED_ORDER_LABEL: 'Carrusel de pedidos sugeridos',
    SUGGESTED_ORDER_DESCRIPTION:
      'Muestra los productos en la página de inicio de la app BEES para aumentar su visibilidad.',
    SUGGESTED_ORDER_PERSONALIZED_LABEL: 'Personalizado',
    SUGGESTED_ORDER_PERSONALIZED_DESCRIPTION: 'Sugiere productos basados en pedidos anteriores.',
    SUGGESTED_ORDER_DEFAULT_LABEL: 'Predeterminado',
    SUGGESTED_ORDER_DEFAULT_DESCRIPTION: 'Sugiere una lista fija de productos.',
    INVENTORY_LABEL: 'Soluciones de inventario',
    INVENTORY_DESCRIPTION: 'Opciones para administrar el inventario.',
    INVENTORY_LIMITED_LABEL: 'Limitado',
    INVENTORY_LIMITED_DESCRIPTION:
      'El pedido del cliente estará limitado por la cantidad del inventario.',
    INVENTORY_BACKORDER_LABEL: 'Pedido pendiente',
    INVENTORY_BACKORDER_DESCRIPTION:
      'Permite al cliente continuar con un pedido si el inventario es cero. Se notificará al cliente sobre los cambios en la entrega.',

    UPSELLING_LABEL: 'Ventana emergente de venta adicional',
    UPSELLING_DESCRIPTION: 'Recomienda diferentes productos para impulsar las ventas.',
    REPLACEMENT_LABEL: 'Reemplazo de existencias agotadas',
    REPLACEMENT_DESCRIPTION:
      'Si el inventario es cero, se sugerirán productos similares como reemplazos.',
  },
  ORDER_CONFIGURATIONS: {
    TITLE: 'Configuraciones de pedidos',
    MINIMUM_ORDER_LABEL: 'Mínimo por pedido',
    MINIMUM_ORDER_SUBTITLE: 'Establece un mínimo para la cantidad del pedido o el monto total.',
    MINIMUM_ORDER_INPUT_LABEL: 'Total de pedido mínimo',
    MAXIMUM_ORDER_LABEL: 'Máximo por pedido',
    MAXIMUM_ORDER_SUBTITLE: 'Establece un máximo para la cantidad del pedido o el monto total.',
    MAXIMUM_ORDER_INPUT_LABEL: 'Total de pedido máximo',
    MINIMUM_MAXIMUM_OPTIONAL: '(opcional)',
    MINIMUM_MAXIMUM_PRODUCT_QUANTITY: 'Cantidad del producto',
    MINIMUM_MAXIMUM_ORDER_TOTAL: 'Total del pedido',
    ZERO_VALUES_PRODUCT: 'Orden con valores de producto cero',
    ZERO_VALUES_PRODUCT_DESCRIPTION:
      'Si la orden acepta productos con valores cero, como envases retornables o redenciones de puntos',
    PICKUP_ORDERS: 'Órdenes de Recogida',
    PICKUP_ORDERS_DESCRIPTION: '¿Quién es el responsable de actualizar las órdenes?',
    VENDOR: 'Vendedor',
    VENDOR_DESCRIPTION: 'El vendedor será responsable de actualizar las órdenes',
    ORDER_SCHEDULER: 'Programador de Órdenes',
    ORDER_SCHEDULER_DESCRIPTION:
      'Una vez que la orden llega a la fecha programada, se actualizará automáticamente',
    UPCOMING_ORDER_PLACEMENT: 'Colocación de Ordenes Próximas',
    UPCOMING_ORDER_PLACEMENT_DESCRIPTION:
      'Establece si un producto se agregará a una orden no entregada',
    REDEMPTIONS: 'Redenciones',
    REDEMPTIONS_DESCRIPTION: '¿Dividir la orden entre productos pagados y redenciones?',
    EMPTIES: 'Envases Vacíos',
  },
  PRODUCTS_AND_CUSTOMERS: {
    TAB_LABEL: 'Productos y clientes',
    CUSTOMERS_CONFIGURATION_TITLE: 'Configuración de clientes',
    ALLOWED_CUSTOMERS: 'Clientes permitidos',
    ALLOWED_CUSTOMERS_DESCRIPTION:
      'Selecciona qué tipos de PDV se permitirán para realizar pedidos.',
    CNPJ_ONLY: 'Solo NIT',
    CNPJ_ONLY_DESCRIPTION: 'Solo los PDV registrados con un NIT podrán realizar pedidos.',
    CNPJ_AND_CPF: 'NIT y NIF',
    CNPJ_AND_CPF_DESCRIPTION: 'Los PDV registrados con un NIF o un NIT podrán realizar pedidos.',
    DELIVERY_METHOD: 'Método de Entrega',
    DELIVERY_METHOD_DESCRIPTION: 'Establece el tipo de entrega que la cuenta puede tener',
    KEY_ACCOUNTS: 'Cuentas Clave',
    DELIVERY_RANGE: 'Rango de Entrega',
    DELIVERY_RANGE_DESCRIPTION: 'Rango de fechas. Ejemplo: 1 a 3 días hábiles',
    DELIVERY_WINDOW: 'Ventana de Entrega',
    DELIVERY_WINDOW_DESCRIPTION: 'Establece una ventana de entrega. Ejemplo: del 2 al 5 de mayo',
    REGULAR_ACCOUNTS: 'Cuentas Regulares',
    ENFORCEMENT: 'Aplicación',
    ENFORCEMENT_DESCRIPTION:
      '¿Pueden los productos y promociones estar activos para las siguientes cuentas?',
    ACCOUNT_CREATION: 'Creación de Cuentas',
    ACCOUNT_CREATION_DESCRIPTION: 'El vendedor puede crear cuentas automáticamente',
    CONTRACT_REGISTRATION: 'Registro de Contratos',
    CONTRACT_REGISTRATION_DESCRIPTION:
      'Permitir que las cuentas soliciten registro y realicen compras si cumplen con las reglas del vendedor',
    KEY_ACCOUNTS_CREATION: 'Creación de Cuentas Clave',
    KEY_ACCOUNTS_CREATION_DESCRIPTION:
      '¿Las Cuentas Clave se registrarán directamente desde el ERP?',
    PRODUCT_CONFIGURATION_TITLE: 'Recomendaciones de productos',
    SUGGESTED_ORDER_CAROUSEL: 'Carrusel de pedidos sugeridos',
    SUGGESTED_ORDER_CAROUSEL_DESCRIPTION:
      'Muestra los productos en la página de inicio de la app BEES para aumentar su visibilidad.',
    SUGGESTED_ORDER_PERSONALIZED: 'Personalizado',
    SUGGESTED_ORDER_PERSONALIZED_DESCRIPTION: 'Sugiere productos basados en pedidos anteriores.',
    SUGGESTED_ORDER_DEFAULT: 'Predeterminado',
    SUGGESTED_ORDER_DEFAULT_DESCRIPTION: 'Sugiere una lista fija de productos.',
    INVENTORY_SOLUTIONS: 'Soluciones de inventario',
    INVENTORY_SOLUTIONS_DESCRIPTION: 'Opciones para administrar el inventario.',
    INVENTORY_LIMITED: 'Limitado',
    INVENTORY_LIMITED_DESCRIPTION:
      'El pedido del cliente estará limitado por la cantidad del inventario.',
    INVENTORY_BACKORDER: 'Pedido pendiente',
    INVENTORY_BACKORDER_DESCRIPTION:
      'Permite al cliente continuar con un pedido si el inventario es cero. Se notificará al cliente sobre los cambios en la entrega.',
    UPSELLING_POPUP: 'Ventana emergente de venta adicional',
    UPSELLING_POPUP_DESCRIPTION: 'Recomienda diferentes productos para impulsar las ventas.',
    OUT_OF_STOCK_REPLACEMENT: 'Reemplazo de existencias agotadas',
    OUT_OF_STOCK_REPLACEMENT_DESCRIPTION:
      'Si el inventario es cero, se sugerirán productos similares como reemplazos.',
    CATALOG_IDENTIFICATION: 'Identificación del Catálogo',
    CATALOG_IDENTIFICATION_DESCRIPTION: 'Cómo se reflejarán los ítems en el catálogo',
    SELL_TO_KEY_ACCOUNTS: 'Vender a Cuentas Clave',
    SELL_TO_KEY_ACCOUNTS_DESCRIPTION: 'Establece si este vendedor puede mostrarse a Cuentas Clave',
    SKU_GROUPED: 'SKU Agrupados',
    SKU_GROUPED_DESCRIPTION: 'Ítems con el mismo SKU se unificarán',
    UNIQUE_SKUS: 'SKUs Únicos',
    UNIQUE_SKUS_DESCRIPTION: 'Todos los ítems aparecerán separados',
    FREE_GOODS: 'Productos Gratuitos',
    FREE_GOODS_DESCRIPTION: 'Habilitar promociones de compra y gana',
  },
  FULFILLMENT: {
    ALLOWED_CUSTOMERS: 'Clientes permitidos',
    POC_TYPES_LABEL: 'Selecciona qué tipos de PDV se permitirán para realizar pedidos.',
    POC_TYPES_CNPJ_LABEL: 'Solo NIT',
    POC_TYPES_CNPJ_DESCRIPTION: 'Solo los PDV registrados con un NIT podrán realizar pedidos.',
    POC_TYPES_CNPJ_AND_CPF_LABEL: 'NIT y NIF',
    POC_TYPES_CNPJ_AND_CPF_DESCRIPTION:
      'Los PDV registrados con un NIF o un NIT podrán realizar pedidos.',
  },
  FORM_MESSAGES: {
    ERROR_REQUIRED_FIELD: 'Este campo es obligatorio',
    ERROR_VALID_NUMBER: 'Por favor, ingrese un número válido',
    ERROR_BIGGER_THAN_ZERO: 'El valor debe ser mayor que cero',
    ERROR_INTEGER_NUMBER: 'El valor debe ser un número entero',
    ERROR_LESS_THAN: 'El valor debe ser menor que {value}',
    ERROR_MAXIMUM_GREATER_THAN_MINIMUM:
      'El valor máximo del pedido debe ser mayor que el valor mínimo del pedido',
  },
  SUCCESS_MESSAGES: {
    CHANGES_SAVED_SUCCESSFULLY: 'Cambios guardados con éxito.',
    COMPANY_ADDED_SUCCESSFULLY: 'Empresa agregada exitosamente.',
    STORE_SUCCESSFULLY_CREATED: 'Tienda creada exitosamente y asociada a la empresa {company}.',
  },
  ERROR_MESSAGES: {
    UNABLE_TO_SAVE_CHANGES: 'No se ha podido guardar los cambios.',
    DUPLICATED_PAYMENT_METHOD: 'Ya hay un método de pago con el mismo nombre añadido',
    UNABLE_TO_CREATE_COMPANY: 'No se ha podido crear la empresa. Intente nuevamente más tarde.',
    UNABLE_TO_CREATE_STORE:
      'No se ha podido crear la tienda y asociarla a la empresa {company}. Intente nuevamente más tarde.',
  },
  COMPANY_ROLES: {
    TITLE: 'Rol de la Empresa',
    SUBTITLE: '¿Cómo actuará esta empresa en Bees?',
    SELLER_TITLE: 'Vendedor',
    SELLER_DESCRIPTION: 'Empresas responsables de la transacción comercial de bienes.',
    MANUFACTURER_TITLE: 'Fabricante',
    MANUFACTURER_DESCRIPTION:
      'Empresas que producen bienes a partir de materias primas a través de una serie de procesos.',
    CORPORATE_TITLE: 'Corporativo',
    CORPORATE_DESCRIPTION: 'Empresas que operan a nivel administrativo.',
    NO_ROLES_SELECTED: 'Ningún rol seleccionado',
  },
};

export default es419;
