import { useHasPermission } from 'admin-portal-shared-services';
import permissions from 'config/permissions';
import { useCountryContext } from 'context/country';
import Countries, { AllCountries } from 'domains/Countries';

interface Permissions {
  canAccessCompanyManagement: boolean;
  canAccessVendorGroup: boolean;
  canEditCompanyDetails: boolean;
  canEditPaymentAndOrders: boolean;
  canEditRecommendationsTab: boolean;
  canEditGeneralInformation: boolean;
  canViewFulfillment: boolean;
  canViewStoreItem: boolean;
}

const canViewFulfillment = (country: AllCountries) => {
  const allowedCountries: Array<AllCountries> = [Countries.BR];

  const isCountryAllowed = allowedCountries.includes(country);

  return isCountryAllowed;
};

export const usePermissions = (): Permissions => {
  const { country } = useCountryContext();

  // General
  const hasReadPermission = useHasPermission(permissions.CompanyManagement.READ);
  const hasWritePermission = useHasPermission(
    [permissions.CompanyManagementLocal.WRITE, permissions.CompanyManagementGlobal.WRITE],
    'OR'
  );

  // Recommendations
  const hasRecommendationsWritePermission = useHasPermission(permissions.Recommendations.WRITE);

  // Payment and Orders
  const hasPaymentAndOrdersWritePermission = useHasPermission(permissions.PaymentAndOrders.WRITE);

  // General Information
  const hasGeneralInformationWritePermission = useHasPermission(
    permissions.GeneralInformation.WRITE
  );

  // Store
  const hasStorePermission = useHasPermission(
    [permissions.StoreManagement.READ, permissions.StoreManagement.WRITE],
    'OR'
  );

  // Vendor Group
  const hasVendorGroupPermission = useHasPermission(
    [permissions.Group.READ, permissions.Group.WRITE],
    'OR'
  );

  return {
    canAccessCompanyManagement: hasReadPermission,
    canAccessVendorGroup: hasVendorGroupPermission,
    canEditCompanyDetails: hasWritePermission,
    canEditPaymentAndOrders: hasPaymentAndOrdersWritePermission,
    canEditRecommendationsTab: hasRecommendationsWritePermission,
    canEditGeneralInformation: hasGeneralInformationWritePermission,
    canViewFulfillment: canViewFulfillment(country),
    canViewStoreItem: hasStorePermission,
  };
};
