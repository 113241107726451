import React, { lazy, memo, ReactNode, Suspense, useEffect } from 'react';
import { BrowserRouter, Redirect, Switch, useLocation } from 'react-router-dom';
import { Grid } from '@hexa-ui/components';
import { Error403 } from 'components/Error/403/403';
import PageLoading from 'components/PageLoading/PageLoading';
import { useClusterContext } from 'context/cluster';
import { useCountryContext } from 'context/country';
import { SupportedCountries } from 'domains/Countries';
import useIsUS from 'hooks/useIsUS';
import { usePermissions } from 'hooks/usePermissions';
import { useSidebarService } from 'hooks/useSidebarService';
import { getCountryFromStoreURL } from 'utils/url';
import { PageRoute } from './PageRoute';

export const BASENAME = '/company-management';
export const STORES_PAGE_URL = `${BASENAME}/stores`;
export const COMPANIES_PAGE_URL_WITHOUT_COUNTRY = `${BASENAME}/companies`;
export const COMPANIES_PAGE_URL = `${BASENAME}/companies/:country`;
export const ADD_COMPANY_PAGE_URL = `${BASENAME}/companies/add-company`;
export const COMPANY_DETAILS_PAGE_URL = `${BASENAME}/companies/:country/:id`;
export const VENDORS_GROUP_PAGE_URL = `${BASENAME}/vendors-group`;

const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const CompanyListPage = lazy(() => import('./pages/CompanyListPage'));
const AddCompanyPage = lazy(() => import('./pages/AddCompanyPage'));
const CompanyDetailsPage = lazy(() => import('./pages/CompanyDetails'));

const StoreCountryManager = ({ children }: { children: ReactNode }) => {
  const { country, setCountry } = useCountryContext();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes(STORES_PAGE_URL)) {
      const storeCountry = getCountryFromStoreURL(pathname) as SupportedCountries;
      if (storeCountry !== country) {
        setCountry(storeCountry);
      }
    }
  }, [pathname]);

  return <>{children}</>;
};

const Router = (): JSX.Element => {
  const { canAccessCompanyManagement } = usePermissions();
  const { isDTC } = useClusterContext();
  const isUS = useIsUS();
  const { country } = useCountryContext();

  const renderRedirectToCompaniesPage = () => (
    <Redirect to={COMPANIES_PAGE_URL.replace(':country', country)} />
  );

  useSidebarService();

  if (!canAccessCompanyManagement) return <Error403 />;

  return (
    <BrowserRouter>
      <Grid.Container
        data-testid="router-company"
        style={{
          rowGap: '1rem',
        }}
        type="fluid"
        sidebar
      >
        <Grid.Item
          xs={12}
          style={{
            flexDirection: 'column',
            padding: '0',
          }}
        >
          <StoreCountryManager>
            <Suspense fallback={<PageLoading />}>
              <Switch>
                <PageRoute
                  path={BASENAME}
                  component={isDTC ? renderRedirectToCompaniesPage : HomePage}
                  exact
                />
                <PageRoute
                  path={COMPANIES_PAGE_URL_WITHOUT_COUNTRY}
                  component={renderRedirectToCompaniesPage}
                  exact
                />
                <PageRoute
                  path={ADD_COMPANY_PAGE_URL}
                  component={isDTC || isUS ? renderRedirectToCompaniesPage : AddCompanyPage}
                  exact
                />
                <PageRoute path={COMPANIES_PAGE_URL} component={CompanyListPage} exact />
                <PageRoute path={COMPANY_DETAILS_PAGE_URL} component={CompanyDetailsPage} exact />
              </Switch>
            </Suspense>
          </StoreCountryManager>
        </Grid.Item>
      </Grid.Container>
    </BrowserRouter>
  );
};

export default memo(Router, () => true);
