enum FeatureTogglesV2 {
  SHOW_EMPTIES_FEATURE = 'vendor-management-empties-feature',
  SHOW_STORES = 'company-ddc-stores',
  SHOW_VENDORS_GROUP = 'company-ddc-vendors-group',
  ENABLE_POLYGON_FEATURE = 'vendor-management-polygon-feature',
  SHOW_MANAGE_STORES = 'company-manage-stores',
  SHOW_VENDOR_SETTINGS = 'company-vendor-settings-enable',
}

export default FeatureTogglesV2;
